import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../common/SEO';
import ComingSoonOne from '../components/coming-soon/ComingSoonOne';
import './ComingSoon.style.css';
import './ComingSoon.style_2.css';
import './ComingSoon.style_3.css';

const ComingSoon = () => {
  return (
    <>
      <SEO title="Coming Soon" />
      <div className="eduvibe-coming-soon edu-coming-soon-area edu-coming-soon-style ptb--150 ptb_md--80 ptb_sm--80 bg-image">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-xl-10 offset-xl-1">
              <div className="content text-center">
                <div
                  className=""
                  // style={{ marginTop: '-200px' }}
                >
                  <Link to="/">
                    <img
                      src="/images/logo/Color logo - no background.png"
                      alt="Logo Thumb"
                      width="390"
                      height="390"
                    />
                  </Link>
                </div>
                <h1
                  className="title"
                  // style={{ marginTop: '-80px' }}
                >
                  {/* We Are Making <br /> Something Specials */}
                  Education is not preparation for life
                  <br /> education is life itself.
                  {/* Teaching is the one profession that <br /> creates all other professions. */}
                  {/* Better than a thousand days of diligent study is one day with a great teacher. */}
                  {/* Education is the most powerful weapon which you can use to change the world. */}
                </h1>
                <div className="countdown-style-2">
                  <div className="countdown">
                    <ComingSoonOne />
                  </div>
                </div>
                <p
                  className="description"
                  style={{
                    // fontWeight: 'bold',
                    fontStyle: 'italic',
                  }}
                >
                  {/* <span
                    style={{
                      fontWeight: 'bold',
                      color: '#027bb0',
                      fontSize: '20px',
                    }}
                  >
                    Russian Student
                  </span> */}
                  <span className="waviy" style={{ fontWeight: 'bold' }}>
                    <span className="w1 primClr">R</span>
                    <span className="w2 primClr">U</span>
                    <span className="w3 primClr">S</span>
                    <span className="w4 primClr">S</span>
                    <span className="w5 primClr">I</span>
                    <span className="w6 primClr">A</span>
                    <span className="w7 primClr">N</span>
                    <span className="w8">&nbsp;</span>
                    <span className="w9 diifClr">s</span>
                    <span className="w10 diifClr">t</span>
                    <span className="w11 diifClr">u</span>
                    <span className="w12 diifClr">d</span>
                    <span className="w13 diifClr">e</span>
                    <span className="w14 diifClr">n</span>
                    <span className="w15 diifClr">t</span>
                  </span>
                  <span
                    style={{
                      // fontWeight: 'bold',
                      color: '#c88E36',
                      fontSize: '18px',
                    }}
                  >
                    - с нами ваш процесс обучения станет проще, продуктивнее и
                    намного комфортнее. Приятные цены с гарантией качества.
                  </span>
                  <br />
                  <br />
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                      fontSize: '20px',
                    }}
                  >
                    <span className="title-word title-word-1">Регистрация</span>
                    <span className="title-word title-word-2"> открыта </span>
                    <span className="title-word title-word-3">
                      на все виды{' '}
                    </span>
                    <span className="title-word title-word-4">
                      студенческих работ!
                    </span>
                  </span>
                </p>
                {/* MY CHANGES START BLOCK */}
                <div className="row g-5 mt--20">
                  <div className="col-md-12">
                    <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                      <div className="row g-5">
                        <div className="col-md-6 col-sm-6 col-12">
                          <div className="contact-address-card-1 email">
                            <div className="inner">
                              <div className="icon">
                                <i className="icon-mail-open-line"></i>
                              </div>
                              <div className="content">
                                <h6 className="title">Email Us</h6>
                                <p>
                                  <a href="mailto:russian.student.kgd@gmail.com">
                                    russian.student.kgd@gmail.com
                                  </a>
                                </p>
                                {/* <p>
                                  <a href="mailto:wpvive@mail.com">
                                    wpvive@mail.com
                                  </a>
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12">
                          <div className="contact-address-card-1 phone">
                            <div className="inner">
                              <div className="icon">
                                <i className="icon-Headphone"></i>
                              </div>
                              <div className="content">
                                <h6 className="title">Call Us On</h6>
                                <p>
                                  <a href="tel: +7 921 711 23 13">
                                    +7 921 711 23 13
                                    {/* +2763 (388) 2930 */}
                                  </a>
                                </p>
                                {/* <p>
                                  <a href="tel: +4875 (356) 2568">
                                    +4875 (356) 2568
                                  </a>
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* MY CHANGES END BLOCK */}
                {/* <div className="newsletter-style-6">
                  <form className="newsletter-form-style-2" action="#">
                    <input type="email" placeholder="Enter your mail address" />
                    <button className="edu-btn">
                      Subscribe Now{' '}
                      <i className="icon-arrow-right-line-right"></i>
                    </button>
                  </form>
                </div> */}
              </div>
            </div>
          </div>

          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="/images/shapes/shape-11-06.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="/images/shapes/shape-09-02.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-3">
              <img src="/images/shapes/shape-03-02.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-4">
              <img src="/images/shapes/shape-14-04.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-5">
              <img src="/images/shapes/shape-03-08.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-6">
              <img src="/images/shapes/shape-01-02.png" alt="Shape Thumb" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ComingSoon;
