import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/scss/style.scss';
import ComingSoon from './pages/ComingSoon';

const App = () => {
  return (
    <>
      <Router>
        <ComingSoon />
      </Router>
    </>
  );
};

export default App;
